export const navLinks = [
  {
    label: 'Projects',
    pathname: '/',
    hash: '#project-1',
  },
  {
    label: 'Certificates',
    pathname: '/',
    hash: '#certificates',
  },
  {
    label: 'About',
    pathname: '/',
    hash: '#about',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Telegram',
    url: 'https://t.me/insanerusty',
    icon: 'telegram',
  },
  {
    label: 'Mail',
    url: 'mailto:kento.suzuki16@gmail.com',
    icon: 'email',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/kento-dev/',
    icon: 'linkedin',
  },
  // {
  //     label: 'Github',
  //     url: 'https://github.com/1nsane-dev',
  //     icon: 'github',
  // },
];
