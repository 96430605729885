import { lazy, Suspense, useEffect, createContext, useReducer, Fragment } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import ThemeProvider from 'components/ThemeProvider';
import VisuallyHidden from 'components/VisuallyHidden';
import { tokens } from 'components/ThemeProvider/theme';
import { msToNum } from 'utils/style';
import { useLocalStorage } from 'hooks';
import { initialState, reducer } from 'app/reducer';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import './reset.css';
import './index.css';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));
const ProjectYat = lazy(() => import('pages/ProjectYat'));
const ProjectJuslaw = lazy(() => import('pages/ProjectJuslaw'));
const ProjectTriton = lazy(() => import('pages/ProjectTriton'));
const ProjectTradeWatch = lazy(() => import('pages/ProjectTradeWatch'));
const ProjectAccutask = lazy(() => import('pages/ProjectAccutask'));
const ProjectStarlix = lazy(() => import('pages/ProjectStarlix'));
const ProjectStarMetals = lazy(() => import('pages/ProjectStarMetals'));
const ProjectDTT = lazy(() => import('pages/DevTechTools'));
const Articles = lazy(() => import('pages/Articles'));
const ProjectDingDash = lazy(() => import('pages/ProjectDingDash'));
const ProjectMotohunt = lazy(() => import('pages/ProjectMotohunt'));
const Uses = lazy(() => import('pages/Uses'));
const ProjectSkyweaver = lazy(() => import('pages/ProjectSkyweaver'));
const ProjectTulip = lazy(() => import('pages/ProjectTulip'));
const Page404 = lazy(() => import('pages/404'));

export const AppContext = createContext();
export const TransitionContext = createContext();

const repoPrompt = `\u00A9 2017-${new Date().getFullYear()} Kento Suzuki`;

const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'dark');
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }

    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider themeId={state.theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={`https://kento-suzuki.xyz${pathname}`} />
      </Helmet>
      <VisuallyHidden showOnFocus as="a" className="skip-to-main" href="#MainContent">
        Skip to main content
      </VisuallyHidden>
      <Navbar location={location} />
      <TransitionGroup component="main" className="app" tabIndex={-1} id="MainContent">
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/projects/yat" component={ProjectYat} />
                    <Route path="/projects/juslaw" component={ProjectJuslaw} />
                    <Route path="/projects/starlix" component={ProjectStarlix} />
                    <Route path="/projects/skyweaver" component={ProjectSkyweaver} />
                    <Route path="/projects/tulip" component={ProjectTulip} />
                    {/* <Route path="/projects/triton" component={ProjectTriton} />
                    <Route path="/projects/accutask" component={ProjectAccutask} />
                    <Route path="/projects/tradewatch" component={ProjectTradeWatch} />
                    <Route path="/projects/starmetals" component={ProjectStarMetals} />
                    <Route path="/projects/dingdash" component={ProjectDingDash} />
                    <Route path="/projects/motohunt" component={ProjectMotohunt} />
                    <Route path="/projects/dtt" component={ProjectDTT} /> */}
                    <Route path="/uses" component={Uses} />
                    <Route path="/articles" component={Articles} />
                    <Route component={Page404} />
                  </Switch>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

export default App;
